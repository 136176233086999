export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  api: 'https://api.panel.carreirasgalegas.com',
  storage:
  {
    basicUrl: 'https://races.blob.core.windows.net',
    circuits: "circuits",
    competitions: "competitions",
    regulations: "regulations",
    sponsors: "sponsors",
    races: "races",
    news: "news",
    mailings: "mailings",
    documents: "documents",
    resultsPdf: "results-pdf",
    bibsImages: "bibs-images",
    bibsDocuments: "bibs-documents",
  },
  firebase: {
    apiKey: "AIzaSyCHjgYqTjWc-k0_uS9w2LNJg2b8RqVM-Gk",
    authDomain: "races-pro.firebaseapp.com",
    projectId: "races-pro",
    storageBucket: "races-pro.appspot.com",
    messagingSenderId: "852206661109",
    appId: "1:852206661109:web:d7053c837285aa57ff84bc"
  },
  useEmulators: false,
  numberItemsBatch: 20,
  signalR:
  {
    hub: "/races-hub",
    receivePercentage: "receivePercentage",
    receiveError: "receiveError",
    receiveChecking: "receiveChecking"
  }
};
